import { graphql, navigate } from 'gatsby'
import React from 'react'
import { BreadCrumbItems } from '../../components/breadCrumb/LocalizedBreadCrumb'
import Button from '../../components/buttons/Button'
import CtaLink from '../../components/CtaLink/CtaLink'
import Layout from '../../components/Layout'
import Title from '../../components/Title'
import { findMenuEntry } from '../../../specific/constants/menu'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { t, textWithLocale } from '../../../common/i18n'
import '../../styles/singlePage.scss'
import route from '../../utils/route'
import styles from './plantilla.module.scss'

const convertToFirstUpperCase = sentence => {
  const lowercase = sentence ? sentence.toLowerCase() : ''
  return sentence ? sentence[0] + lowercase.slice(1) : ''
}

const PageTemplate = ({ data }) => {
  useSelectedMenu(data.plantillas.id_menu_principal)

  const breadCrumItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
  ] as BreadCrumbItems

  const principal = findMenuEntry(data.plantillas.id_menu_principal)
  if (principal) {
    breadCrumItems.push({
      display: t(principal.name),
      link: route(principal.route),
    })
  }

  data.plantillas.site_distributiva &&
    breadCrumItems.push({
      display: data.plantillas.site_distributiva[textWithLocale('titulo')],
      link: data.plantillas.site_distributiva[textWithLocale('nombre')],
    })

  breadCrumItems.push({
    display: convertToFirstUpperCase(data.plantillas[textWithLocale('titulo')]),
  })
  return (
    <Layout
      selector={false}
      breadCrumItems={breadCrumItems}
      seoData={data.seoData}>
      <div className={styles.ficha_container}>
        <Title
          text={convertToFirstUpperCase(
            data.plantillas[textWithLocale('titulo')]
          )}
        />
        <img
          className={styles.ficha_imagen}
          src={data.plantillas[textWithLocale('image_url')]}
          alt="Imagen ficha"
        />
        {/* <div className={styles['ficha_imagen']} style={{ backgroundImage: `url(${data.plantillas[textWithLocale('image_url')]})` }}/> */}
        <div className={styles.ficha_container_inferior}>
          <div className={styles.ficha_container_izquierda}>
            <div
              className={styles.ficha_texto_container}
              dangerouslySetInnerHTML={{
                __html: data.plantillas[textWithLocale('texto')],
              }}></div>
            {data.plantillas.cta !== 'cita_previa' ? (
              <Button
                onClick={() =>
                  navigate(data.plantillas[textWithLocale('link_cta')])
                }>
                {data.plantillas[textWithLocale('cta')]}
              </Button>
            ) : null}
            {data.plantillas.cta === 'cita_previa' ? (
              <CtaLink
                data={{
                  cta: data.plantillas.cta,
                  cta_es: data.plantillas.cta_es,
                  cta_ca: data.plantillas.cta_ca,
                  cta_pt: data.plantillas.cta_pt
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query plantillaPageQuery($id_site_plantilla: Int!, $url: String!) {
    plantillas(id_site_plantilla: { eq: $id_site_plantilla }) {
      id_site_plantilla
      id_menu_principal
      nombre
      nombre_es
      nombre_ca
      nombre_pt
      tipo
      titulo_es
      titulo_ca
      titulo_pt
      texto_ca
      texto_es
      texto_pt
      image_url_es
      image_url_ca
      image_url_pt
      site_distributiva {
        id_site_distributiva
        nombre_es
        nombre_ca
        nombre_pt
        titulo_es
        titulo_ca
        titulo_pt
      }
      image_url_mobile_es
      image_url_mobile_ca
      image_url_mobile_pt
      cta_descripcion_es
      cta_descripcion_ca
      cta_descripcion_pt
      cta
      cta_es
      cta_ca
      cta_pt
      link_cta_es
      link_cta_ca
      link_cta_pt
      Relacionadas {
        id_site_plantilla
        titulo_es
        titulo_ca
        titulo_pt
        texto_ca
        texto_es
        texto_pt
        image_url_es
        image_url_ca
        image_url_pt
      }
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
